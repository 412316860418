<template>
  <div class="map-afisha" v-if="!isMobile" ref="mapAfisha" @scroll="handlerScroll">
    <LoadingBarElement :loading="loading"/>
    <h2 class="map-afisha-label-section">{{ $t('map:Interesting places and events') }}: {{ numberExp }}</h2>
    <div class="map-afisha-scrollable">
      <template v-for="Exp in experience">
        <!--    TODO Сделать один компонент Poster      -->
        <ExperiencePosterMobile
            :experience="Exp"
            :key="'ExperiencePoster_' + Exp.id"
            :mainevent="mainEvent"
            :get-transfer-url="getTransferUrl"
            @openCard="$emit('openCard', $event)"
            v-if="Exp"
        />
      </template>
    </div>

    <div class="alt-block" v-show="!loading && !filteredExperience.length && !loadingMap">
      <h3 class="alt-block-title">{{ $t('map:No events found') }}</h3>
      <p class="alt-block-desc">{{ $t('map:Change the search area or filter options') }}</p>
    </div>

  </div>

  <transition name="slide-fade" v-else>
    <div class="map-afisha-mobile-wrap">
      <buttons-component>
        <button-component type="map" @click="$emit('closeAfisha')"/>
        <button-component type="filter" @click="$emit('openFilter')">
          <template #suffix><span v-if="isChangedFilter" class="change-icon"></span></template>
        </button-component>
      </buttons-component>
      <div class="map-afisha-mobile" ref="mapAfisha" @scroll="handlerScroll">
        <LoadingBarElement :loading="loading"/>
        <h2 class="map-afisha-label-section">{{ $t('map:Interesting places and events') }}: {{ numberExp }}</h2>
        <div class="map-afisha-scrollable">
          <template v-for="Exp in experience">
            <ExperiencePosterMobile
                :experience="Exp"
                :key="'ExperiencePoster_' + Exp.id"
                :mainevent="mainEvent"
                :is-mobile="isMobile"
                :get-transfer-url="getTransferUrl"
                @openCard="$emit('openCard', $event)"
                v-if="Exp"
            />
          </template>
        </div>
      </div>


      <div class="alt-block" v-show="!loading && !filteredExperience.length && !loadingMap">
        <h3 class="alt-block-title">{{ $t('map:No events found') }}</h3>
        <p class="alt-block-desc">{{ $t('map:Change the search area or filter options') }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
// import ExperiencePoster from "@/component/mapV2/marker_map_event/ExperiencePoster";
import ExperiencePosterMobile from "@/component/mapV2/marker_map_event/ExperiencePosterMobile";
import LoadingBarElement from '@/component/element/loadingBar'
import ButtonsComponent from "@/component/element/ButtonsComponent.vue";
import ButtonComponent from "@/component/element/ButtonComponent.vue";

export default {
  name: "afisha",
  components: {
    ButtonComponent,
    ButtonsComponent,
    // ExperiencePoster,
    ExperiencePosterMobile,
    LoadingBarElement,
  },
  props: {
    isMobile: Boolean,
    mainEvent: Object,
    getTransferUrl: Function,
    filteredExperience: Array,
    isShowAfisha: Boolean,
    isChangedFilter: Boolean,
    numberExp: Number,
    mapBounds: Object,
    loadingMap: Boolean
  },
  data() {
    return {
      itemsI: 20,
      numberItemsShown: 0,
      experience: [],
      loading: true
    }
  },
  watch: {
    mapBounds() {
      this.$refs.mapAfisha.scroll(0, 0)
    },
    filteredExperience(items) {
      let i = 0

      this.loading = true
      this.experience = []
      this.numberItemsShown < this.itemsI && (this.numberItemsShown = this.itemsI)

      items.some(item => {
        i = ++i
        item.loadData()

        this.experience.push(item)

        return this.numberItemsShown === i
      })

      this.numberItemsShown = i
      this.loading = false
    },
  },
  methods: {
    handlerScroll(e) {
      if ( (e.target.scrollTop + e.target.clientHeight) >= (e.target.scrollHeight - 10)) this.addingItems()
    },
    addingItems() {
      let i = 0

      this.loading = true
      this.filteredExperience.slice(this.numberItemsShown).some(item => {
        i = ++i
        item.loadData()

        this.experience.push(item)

        return i === this.itemsI
      })
      this.numberItemsShown += i
      this.loading = false
    }
  }
}
</script>

<style lang="scss">

.map-afisha {
  position: relative;
  left: 0;
  top: 0;
  background: #fff;
  height: 100%;

  z-index: 1000;
  font-size: 16px;
  color: white;
  overflow-y: scroll;
  //background: rgba(255, 255, 255, 0.8);

  .map-afisha-label-section {
    color: black;
    margin: 0;
    padding: 15px 10px;
  }

  & .map-afisha-scrollable {
    font-size: .8rem;
    padding: 0 5px;

    & .v-card {
      font-size: 1em;

      & .v-card__actions {
        padding: 0 0.6em 0.5em;
      }
    }
  }

  .iw-custom-popup {
    //box-shadow: 3px 3px 3px gray;
    //margin-right: 20px;
    width: 310px;
    min-width: 310px;
    background: rgba(255, 255, 255, 0.8);

    .iw-custom-popup-inner {
      box-shadow: 3px 3px 3px gray;
    }

    .iw-custom-popup__wrapper-header-main {
      height: 115px;
      background: #000;
    }

    .iw-custom-popup__hotel-name {
      text-transform: uppercase;
      padding: 5px 5px 0 5px;
      color: #000 !important;
    }

    .iw-custom-popup__room-address {
      overflow: hidden;
      margin-bottom: 2px;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: bold;
      font-size: 13px;
      /* color: rgba(255, 255, 255, 0.8); */
      display: block;
      padding: 5px 5px 0 5px;
      color: #434343 !important;
    }

    footer {
      display: block;
      height: 70px;

      .iw-custom-popup__link {
        height: unset;
        padding: 3px 5px;
      }
    }
  }

  .map-afisha-popup {
    margin-right: 20px;
    width: 290px;
    min-width: 290px;
    height: 140px;

    .iw-custom-popup__wrapper-header-main {
      height: 115px;
    }
  }

  .map-afisha-close-button {
    position: fixed;
    margin-top: -25px;
    right: 25px;
    color: black;
    display: none;
  }

  .iw-custom-popup__background {
    opacity: 0.9;

    &::before {
      background: transparent;
    }
  }
}

.map-afisha-mobile-wrap {
  width: 100%;
  display: flex;
  flex-flow: column;
  position: absolute;
  z-index: 3001;
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  height: 100%;
  overflow: hidden;

  .map-afisha-mobile {
    height: 100%;
    overflow: auto;
    background-color: rgb(255, 255, 255);

    .map-afisha-label-section {
      color: black;
      margin: 0;
      padding: 25px 10px 15px;
    }

    & .map-afisha-scrollable {
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 5px;
    }
  }
}

.change-icon {
  width: .7em;
  height: .7em;
  background-color: var(--color-hover);
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  margin-left: 4px;
}

.alt-block {
  position: absolute;
  color: black;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-right: -50%;
  letter-spacing: 1.5px;
  text-align: center;

  .alt-block-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 1px;
  }

  .alt-block-desc {
    white-space: pre;
    margin-top: 1em;
    text-align: center;
  }
}
</style>