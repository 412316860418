<template>
  <div class="iw-custom-popup ">
    <div class="iw-custom-popup__wrapper-header-main">

      <main class="iw-custom-popup__main">
        <div class="iw-custom-popup__hotel-name">{{poi.description}}</div>
        <div class="iw-custom-popup__wrapper-distance-rooms">
          <div class="iw-custom-popup__distance"></div><!-- Inserting text from getHotelPopup.js -->
        </div>
      </main>
      <div class="iw-custom-popup__background" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.9) 55.96%, rgba(0, 0, 0, 1) 99.06%)"></div>
    </div>

    <footer class="iw-custom-popup__footer">
      <a class="iw-custom-popup__link iw-custom-popup__link" style="padding-left: 10px;" :href="poi.url" target="_blank" onclick="Analytic.send('Map POI Open Link partner')">{{$t('map:Show details')}}</a>
    </footer>
  </div>
</template>

<script>
  export default {
    props: {
      poi: Object,
    },
    computed:{}
  }
</script>

