
import { isMobile } from 'mobile-device-detect';
import getDirectionLibrary from "@/component/mapV2/getDirectionLibrary";

export default (option = {}, closeAllMarker) => {

  let IwMarkerZIndex       = 1000;
  let IwMarkerZIndexOpen   = 5000;

  let IwMarker             = null;
  let IwPopup              = null;
  let IwDirection          = null;
  let IwDirectionPromise   = null;

  let IwMarkerDetailsOpen  = false;

  let IwMarkerInFocus      = false;
  let IwPopupInFocus       = false;

  const MarkerInFocus = () => {
    return IwMarkerInFocus || IwPopupInFocus;
  };

  const openMarkerDetails = () => {
    if(IwMarkerDetailsOpen){return;}
    IwMarkerDetailsOpen = true;
    IwMarker.setZIndexOffset(IwMarkerZIndexOpen);

    window.fbq && window.fbq('track', 'ViewContent');
    Analytic.send('Popup view', {name:option.name, partner_name: option.name});
    IwMarker && IwMarker.openPopup();
    if(option.direction){
      if(!IwDirection){
        let dist = getDistanceFromLatLonInKm(option.direction.from.lat, option.direction.from.lon, option.direction.to.lat, option.direction.to.lon);
        option.direction.travelMode = 'DRIVING';
        if(dist < 1){
          option.direction.travelMode = 'WALKING';
        }

        IwDirectionPromise = getDirectionLibrary(option.direction.from, option.direction.to, {travelMode: option.direction.travelMode})
        IwDirectionPromise.then((direction) => {
          
          if(direction.distance < 1000){
            option.direction.distance = direction.distance + ' ' + VueApp.$t('map:m') || '';
          } else {
            option.direction.distance = Math.round(direction.distance / 1000) + ' ' + VueApp.$t('map:km') || '';
          }

          if (direction.duration < 60) {
            option.direction.duration = '1' + ' ' + VueApp.$t('map:min');
          } else{
            option.direction.duration = Math.round(direction.duration / 60) + ' ' + VueApp.$t('map:min') || '';
          }
          
          option.direction.type = direction.travelMode === 'WALKING' ? VueApp.$t('walking') : VueApp.$t('driving')
          
          IwDirection = new L.polyline(direction.points, {color: option.direction.color || 'rgba(0, 0, 0, 0.6)', weight: option.direction.weight || 4});
          IwMarkerDetailsOpen && IwMarker._map && IwMarker._map.addLayer(IwDirection);
          option.direction.callback && option.direction.callback(direction);
        })
      } else {
        IwDirection && IwMarker._map && IwMarker._map.addLayer(IwDirection);
      }
    }
    
    // addToMap && addToMap.addLayer(markerPolyline);

    // TODO: показать круг
    // TODO: показать маршрут
  };
  const closeMarkerDetails = () => {
    if(!IwMarkerDetailsOpen){ return; }
    IwMarkerDetailsOpen = false;
    IwMarker.setZIndexOffset(IwMarkerZIndex);

    IwMarker && IwMarker.closePopup();

    IwDirection && IwDirection._map && IwDirection._map.removeLayer(IwDirection);
    // TODO: скрыть круг
    // TODO: скрыть маршрут
  };


  const openMarkerDetailsDeferred = () => {
    setTimeout(() => {
      if( MarkerInFocus() ){
        closeAllMarker && closeAllMarker();
        openMarkerDetails();
      }
    }, 200);
  };
  const closeMarkerDetailsDeferred = () => {
    setTimeout(() => {
      if( !MarkerInFocus() ){
        closeMarkerDetails();
      }
    }, 600);
  };

  const initMarker  = (event) => {
    IwMarker = event.target;

    // re-init event
    IwMarker.off('click');
    IwMarker.on('click', (event) => {
      if(event.originalEvent && !isMobile){
        // для ПК обрабатываем событие перехода по ссылке
      } else {
        if(IwMarkerDetailsOpen){
          closeMarkerDetails && closeMarkerDetails()
        } else {
          closeAllMarker && closeAllMarker();
          openMarkerDetails();
        }
      }
    });


    IwMarker.on('mouseover', () => {
      IwMarkerInFocus = true;
      if(IwMarkerDetailsOpen){return;}
      openMarkerDetailsDeferred();
    });
    IwMarker.on('mouseout', () => {
      IwMarkerInFocus = false;
      closeMarkerDetailsDeferred();
    });

  };

  const initMarkerPopup  = (event) => {
    // инициализируем ток 1 раз
    if(IwPopup){ return;}
    IwPopup             = event.target;

    IwPopup._container.addEventListener('mouseenter', () => {
      IwPopupInFocus = true;
    });
    IwPopup._container.addEventListener('mouseleave', () => {
      IwPopupInFocus = false;
      closeMarkerDetailsDeferred();
    });
  };

  function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  }
  
  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }
  
  function fixLatLng (direction = {}) {
    option.direction = direction;
  }

  return {
    initMarker,
    initMarkerPopup,
    isOpen: () => { return IwMarkerDetailsOpen; },
    openMarkerDetails,
    closeMarkerDetails,
    fixLatLng
  }
}
