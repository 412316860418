<template>
  <div class="hotel-map-wrap" :class="{'mobile': isMobile}">

    <div class="aside">
      <div class="header">
        <buttons-component
            :is-mobile="isMobile"
            :is-show-filter="isShowFilter"
            :is-change-filter="($refs.filter || {}).isChange || false"
            :is-changed-filter="($refs.filter || {}).isChanged || false"
            :is-show-card="isShowCard"
            @openAfisha="toggleDisplayAfisha(true)"
            @openFilter="toggleDisplayFilter(true)"
            @closeFilter="toggleDisplayFilter(false)"
            @resetFilter="$refs.filter.resetFilter()"
            @applyFilter="$refs.filter.applyFilter(); isShowFilter = false; !isMobile && (isShowAfisha = true)"
        />
      </div>

      <afisha
          v-show="isShowAfisha"
          :isMobile="isMobile"
          :mapBounds="mapBounds"
          :mainEvent="event"
          :filteredExperience="filteredExperience"
          :isShowAfisha="isShowAfisha"
          :is-changed-filter="($refs.filter || {}).isChanged || false"
          :get-transfer-url="getTransferUrl"
          :numberExp="numberExp"
          :loading-map="loadingMap"
          @closeAfisha="isShowAfisha = false"
          @openFilter="isShowAfisha = false; isShowFilter = true"
          @openCard="openCard($event)"
      />

      <FilterComponent
          ref="filter"
          v-show="isShowFilter"
          :isMobile="isMobile"
          :filter="filter"
          :clean-filter="cleanFilter"
          :event-categories="client.categories.events"
          :venue-categories="client.categories.venues"
          :prices="filterPrices"
          @openAfisha="isShowFilter = false; isShowAfisha = true"
          @closeFilter="isShowFilter = false"
          @applyFilter="Object.assign(filter, $event)"
      />

      <ExperienceCard
          v-if="!isMobile"
          v-show="isShowCard"
          :is-show-card="isShowCard"
          :is-changed-filter="($refs.filter || {}).isChanged || false"
          :Event="cardEvent"
          :get-transfer-url="getTransferUrl"
          @close="isShowCard = false"
          @openFilter="toggleDisplayFilter(true)"
      />
    </div>

    <div class="hotel-content-wrap">

      <div class="header" v-if="!isMobile">
        <div class="fun-tam" v-if="settings.showCallToActions">
          <a :href="settings.labelSettings.websiteLink" target="_blank">
            <LabelIcon :label="settings.labelSettings.callToActionsIcon" />
          </a>
          <a :href="settings.labelSettings.websiteLink" target="_blank">
            <span class="label">{{ settings.labelSettings.label }} </span>
          </a>
          <span class="description">{{ settings.labelSettings.callToActionsDescription }}</span>
        </div>
        <div class="app-links">
          <LinkToAppStore :href="settings.labelSettings.appStoreLink" target="_blank" class="store-link" badge="appStore" :lang="settings.lang" />
          <LinkToAppStore :href="settings.labelSettings.googlePlayLink" target="_blank" class="store-link" badge="googlePlay" :lang="settings.lang" />
        </div>
      </div>

      <div class="leaflet-map-wrap" :style="!mapIsActive ? {pointerEvents: 'none'} : {}">
        <TopMenuComponent
          :filter="filter"
          :lang="settings.lang"
          :event="event"
          @applyFilter="Object.assign(filter, $event)"
          @changeLocation="$emit('changeLocation')"
        />
        <LoadingBarElement :loading="loadingMap"/>
        <LMap
            ref="map"
            :min-zoom="13"
        >
          <!-- for test-->
          <!-- <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>-->
          <v-tilelayer-googlemutant
              apikey="AIzaSyBYzNXiAFrpw4hrps2HyifqniN5RYAkFfU"
              :options="mapOptions"
          />

          <LMarkerByEvent
              v-if="event.isShowing"
              :event="event"
          />

          <template v-for="hotel in hotelList">
            <LMarkerByHotel
                :key="hotel.id"
                displayPrice="total"
                :hotel="hotel"
            />
          </template>

          <template v-for="hotel in partnerHotels">
            <LMarkerByHotel
                :key="hotel.id"
                displayPrice="total"
                :hotel="{
            provider: 'partner',
            latitude: hotel.latitude,
            longitude: hotel.longitude,
            room:{
              price: {
                [hotel.currency]: parseFloat(hotel.price)
              }
            }
          }"
            />
          </template>

          <template v-for="(poiItem, index) in poi">
            <LMarkerByPoi
                :poi="poiItem"
                :key="index"
            />
          </template>

          <template v-for="experienceItem in filteredExperience">
            <LMarkerByMapEvent
                :experience="experienceItem"
                :key="'filteredExperience_' + experienceItem.id"
                :mainevent="event"
                :isMobile="isMobile"
                :shareId="shareId"
                :settings="settings"
                ref="filteredExperiences"
                @closeAllMarker="closeAllMarker"
                @openCard="openCard($event)"
                v-if='experienceItem'
            />
          </template>

          <template v-for="(customPOI, index) in customPOIlist">
            <LMarkerByCustomPOI
                :key="'customPOI_' + index"
                :experience="customPOI"
                ref="customPOIlist"
                @closeAllMarker="closeAllMarker"
            />
          </template>

        </LMap>

        <hotelMapV2Navigation
            :map="mapFix"
            :event="event"
            :hotelList="[]"
            :partnerHotels="[]"
            :poi="[]"
            :covidBanner="false"
            :displayPrice="displayPrice"
            :mapZoom="mapZoom"
            :showSecondSettings="true"
            :showCTA="false"
            :isMobile="isMobile"
            :isShowButtonAfish="!!filteredExperience"
            :settings="settings"
            :appLink="appLink"
        />
      </div>
    </div>

    <div class="bottom-aside" v-if="isMobile">
      <a :href="appLink" target="_blank" class="link get-app-link">
        {{ $t('map:Get the FREE app') }}
      </a>
    </div>

    <ExperienceCard
        v-if="isMobile"
        v-show="isShowCard"
        :is-show-afisha="isShowAfisha"
        :is-changed-filter="($refs.filter || {}).isChanged || false"
        :Event="cardEvent"
        :get-transfer-url="getTransferUrl"
        @close="isShowCard = false"
        @openMap="toggleDisplayMap(true)"
        @openAfisha="toggleDisplayAfisha(true)"
        @openFilter="toggleDisplayFilter(true)"
    />

    <bottomSheet/>
    <!--
    <div class="map-categories">
      <div class="map-category music">Music</div>
      <div class="map-category bus">Bus</div>
      <div class="map-category tour">Tour</div>
    </div>
    -->
  </div>
</template>

<script>

import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant/Vue2LeafletGoogleMutant'
import {LMap} from "vue2-leaflet";

import Event from "@/class/Event";
import LMarkerByEvent from "@/component/mapV2/event/LMarkerByEvent";
import LMarkerByCustomPOI from "@/component/mapV2/custom_poi3/LMarkerByExperience";
import LMarkerByPoi from "@/component/mapV2/poi/LMarkerByPoi";
import LMarkerByHotel from "@/component/mapV2/hotel/LMarkerByHotel";
import LMarkerByMapEvent from "@/component/mapV2/marker_map_event/LMarkerByMapEvent";
import bottomSheet from "@/component/mapV2/bottomSheet";
import afisha from "@/component/mapV2/afisha";
import FilterComponent from "@/component/mapV2/FilterComponent";
import TopMenuComponent from "@/component/mapV2/TopMenuComponent.vue";

import hotelMapV2Navigation from "@/component/mapV2/hotelMapV2Navigation";

import {isMobile} from 'mobile-device-detect';
import expMoscow from '@/component/mapV2/experience/moscow.json'
import expSPB from '@/component/mapV2/experience/spb.json'
import expGermany from '@/component/mapV2/experience/germany.json'
import LabelIcon from '@/component/icons/LabelIcon'
import ExperienceCard from '@/component/mapV2/experience/ExperienceCard'
import LinkToAppStore from '@/component/element/link-to-app-store'
import {mapActions} from "vuex";
import moment from "moment";
import LoadingBarElement from "@/component/element/loadingBar.vue";
import ButtonsComponent from "@/component/element/ButtonsComponent.vue"
import Client from "@/class/client/Client";

export default {
  name: 'HotelMapV2',

  components: {
    LMarkerByEvent,
    LMarkerByPoi,
    LMarkerByHotel,
    //LMarkerByVenue,
    LMarkerByMapEvent,
    //LMarkerByExperience,
    LMarkerByCustomPOI,
    // LPopup,
    LMap,
    // LTileLayer,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant,
    hotelMapV2Navigation,
    bottomSheet,
    afisha,
    FilterComponent,
    LabelIcon,
    ExperienceCard,
    LinkToAppStore,
    TopMenuComponent,
    LoadingBarElement,
    ButtonsComponent,
  },

  props: {
    event: Event,

    hotelList: Array,
    hotelSelected: Object,
    partnerHotels: Array,
    client: Client,

    poi: Array,

    eventLogo: String,
    displayPrice: String,
    mapZoom: Number,
    covidBanner: {
      type: Boolean,
      default: true,
    },
    settings: Object,
    shareId: {
      type: [String, Number],
      default: undefined
    },
    shareType: {
      type: [String],
      default: undefined
    },
  },

  watch: {
    filter: {
      handler() {
        this.setExperience()
      },
      deep: true
    },
    'event.venue': {
      handler(newValue, oldValue) {
        if (newValue.lat !== oldValue.lat || newValue.lon !== oldValue.lon) {
          this.toggleDisplayAfisha(true)
          this.mapFix.setView([newValue.lat, newValue.lon], this.mapFix.getZoom())
          this.mapFix.fire('moveend', {
            Experience: {
              activateTopExp: true
            }
          })
        }
      },
      deep: true
    },
  },
  methods: {
    ...mapActions(['closeBottomSheet']),
    LogOutbound() {
      Analytic.send('Outbound poster board', {url: this.experienceUrl, partner_name: this.event.name});
      window.fbq && window.fbq('track', 'InitiateCheckout');
    },
    initMap() {
      const iwMap = this.$refs.map.mapObject;
      iwMap.attributionControl.getContainer().remove()

      let mapView
      let mapZoom = this.mapZoom

      new Promise(async (resolve, reject) => {
        await this.client.categories.load()
        this.filterInit()
        if (!this.shareId) return reject()

        this.client.experiencesRequest.getById(this.shareId, {type: this.shareType})
            .then(response => {
              const Exp = this.client.experiences.new(response)
              Exp.isShare = true
              mapView = Exp.coordinates.coordinates.reverse()
              this.filteredExperience.push(Exp)

              const requestData = {
                reload: true,
                venue: {
                  lat: Exp.coordinates.coordinates[0],
                  lon: Exp.coordinates.coordinates[1],
                }
              }

              this.$store.dispatch('eventWidget_load', {
                loadType: 'eventByData',
                request: {
                  ...this.$store.state.eventWidget.request,
                  ...requestData
                },
              }).then(() => this.$emit('changeLocation'));
              resolve()
            })
            .catch(error => {
              this.$notify({
                type: 'error',
                title: this.$t('notify:Event not found')
              })
              console.debug(error)
              return reject()
            })
      })
          .catch(() => mapView = this.event ? [this.event.venue.lat, this.event.venue.lon] : [0, 0])
          .finally(() => {
            iwMap.setView(mapView, mapZoom);
            iwMap.zoomControl && iwMap.zoomControl.remove();

            this.mapFix = iwMap;

            let getMapState = () => {
              let centerLatLon = iwMap.getCenter();
              let dist1 = centerLatLon.distanceTo(iwMap.getBounds().getNorthEast());
              let dist2 = centerLatLon.distanceTo(iwMap.getBounds().getSouthWest());
              let dist = dist1 > dist2 ? dist1 : dist2;

              return {
                center: centerLatLon,
                radius: dist,
              };
            }

            let moveEndFunc = (options = {}) => {
              let stateMoveend = getMapState();

              options.Experience = {
                activateTopExp: false,
                init: false,
                ...options.Experience
              }
              this.setExperience(options.Experience);
              this.$emit('map-moveend', stateMoveend);
              this.mapBounds = this.mapFix.getBounds()
              Analytic.send('Map Interaction', {partner_name: this.event.name});
            }
            iwMap.iwMethods = {
              moveEndFunc,
              attachMoveEndFunc() {
                iwMap.on('moveend', this.moveEndFunc);
              },
              detachMoveEndFunc() {
                iwMap.off('moveend', this.moveEndFunc);
              },
              disable: () => this.mapIsActive = false,
              enable: () => this.mapIsActive = true
            }
            this.mapFix.iwMethods.attachMoveEndFunc()
            moveEndFunc({
              Experience: {
                activateTopExp: true,
                init: true
              }
            });
            //this.setExperience();
            //this.setCustomPOIlist();
          })
    },
    setExperience(options = {}) {
      if (!this.mapFix) {
        return [];
      }
      let MapBounds = this.mapFix.getBounds(),
          requestParams = {}

      this.loadingMap = true
      requestParams.boundingBox = [
        [
          MapBounds._southWest.lng, // Левая граница Долгота
          MapBounds._northEast.lat // Верхняя граница Широта
        ],
        [
          MapBounds._northEast.lng, // Правая граница
          MapBounds._southWest.lat // Нижняя граница
        ]
      ]
      requestParams.sortBy = 'distance'
      requestParams.centerCoordinates = [this.mapFix.getCenter().lng, this.mapFix.getCenter().lat]

      this.filter.eventCategories.length ? requestParams.eventCategoryIDs = this.filter.eventCategories : delete requestParams.eventCategoryIDs
      this.filter.venueCategories.length ? requestParams.venueCategoryIDs = this.filter.venueCategories : delete requestParams.venueCategoryIDs
      this.filter.prices.length ? requestParams.price_ranges = this.filter.prices : delete requestParams.price_ranges
      this.filter.dates.dateFrom ? requestParams.fromDate = this.filter.dates.dateFrom.format('YYYY-MM-DD') : delete requestParams.fromDate
      this.filter.dates.dateTo ? requestParams.toDate = this.filter.dates.dateTo.format('YYYY-MM-DD') : delete requestParams.toDate
      this.filter.type ? requestParams.type = this.filter.type : delete requestParams.type

      if (!this.filter.type) {
        if (requestParams.venueCategoryIDs && !requestParams.eventCategoryIDs) requestParams.eventCategoryIDs = []
        if (requestParams.eventCategoryIDs?.length && !requestParams.venueCategoryIDs) requestParams.venueCategoryIDs = []
      }

      if (this.shareType && requestParams.type && this.shareType !== requestParams.type) {
        delete requestParams.type
        this.filter.type = ''
      }

      this.expRequestConfig.source && this.expRequestConfig.source.cancel()
      this.client.experiencesRequest.get(requestParams, this.expRequestConfig)
        .then(response => {
          let topExp,
              topEvent,
              topEventByCoordinates,
              topVenue,
              topVenueByCoordinates

          // Сброс
          this.numberExp = 0
          this.renderList = []
          clearTimeout(this.renderTimeId);

          // Если событий не найдено
          if (!response.items || !response.items.length) {
            if (options.init && options.numRequest !== 3) {
              this.mapFix.iwMethods.detachMoveEndFunc()
              this.mapFix.once('moveend', () => {
                this.mapFix.iwMethods.attachMoveEndFunc()
                this.mapFix.iwMethods.moveEndFunc({
                  Experience: {
                    activateTopExp: true,
                    init: true,
                    numRequest: ++options.numRequest || 1
                  }
                })
              })
              this.mapFix.zoomOut()
              return
            }
            this.mapFix.iwMethods.enable()

            this.loadingMap = false
            this.closeBottomSheet()
            if (this.isMobile) VueApp.$notify({
              type: 'warn',
              title: this.$t('map:No events found'),
              text: this.$t('map:Change the search area or filter options')
            })
            return this.filteredExperience = this.filteredExperience.filter(Exp => Exp.id === this.shareId)
          }

          this.mapFix.iwMethods.enable()

          // Удаление точек, не пришедших в ответе
          for (let i = 0; i < this.filteredExperience.length; i++) {
            if (!this.filteredExperience[i].isShare) {
              if (!response.items.find(item => item.id === this.filteredExperience[i].id)) {
                if (this.filteredExperience[i].isActive) this.closeBottomSheet()
                if (this.filteredExperience[i].iwMarker) this.filteredExperience[i].iwMarker.closeMarkerDetails()
                this.filteredExperience.splice(i, 1)
                --i
              }
            } else {
              topExp = this.filteredExperience[i]
              ++this.numberExp
            }
          }

          response.items.forEach(item => {
            let existExp,
                Exp = this.client.experiences.new(item)

            if (!Exp.category) return
            if (Exp.id !== this.shareId) ++this.numberExp

            // Поиск существующих
            existExp = this.filteredExperience.find(filteredExp => {
              if (filteredExp.id === Exp.id) {
                Exp = filteredExp
                return true
              }
              return false
            })

            // Добавление новых
            if (!existExp) {
              if (this.event.isShowing) Exp.setDirection(this.event.venue)
              this.renderList.push(Exp);
            }

            // Поиск топового события
            if (!this.shareId)
              if (options.activateTopExp) {
                if (Exp.isType('event')) {
                  if (!topEvent || Exp.rating > topEvent.rating) topEvent = Exp
                  if (!topEventByCoordinates || Exp.rating > topEventByCoordinates.rating)
                    if (this.isEventInValidCoordinatesForPopup(Exp, MapBounds)) topEventByCoordinates = Exp
                } else {
                  if (!topVenue || Exp.rating > topVenue.rating) topVenue = Exp
                  if (!topVenueByCoordinates || Exp.rating > topVenueByCoordinates.rating)
                    if (this.isEventInValidCoordinatesForPopup(Exp, MapBounds)) topVenueByCoordinates = Exp
                }
              }

            if (this.shareId && options.activateTopExp && this.shareId === Exp.id) topEvent = Exp
          })
          if (options.activateTopExp) {
            topExp = topEventByCoordinates || topVenueByCoordinates || topEvent || topVenue
            topExp.setActive(true)
            if (this.shareId) this.openCard(topExp)
            this.renderList.find((item, index) => {
              if (topExp !== item) return false;
              this.renderList.splice(index, 1)
              this.renderList.unshift(topExp)
              return true;
            })
          }
          this.renderExperience()
      })
        .catch(error => {
          this.loadingMap = false
          console.debug(error)
        })
    },
    renderExperience() {
      this.renderList.splice(0, 1).forEach(item => this.filteredExperience.push(item))
      if (this.renderList.length) this.renderTimeId = setTimeout(this.renderExperience)
      else this.loadingMap = false
    },
    openCard(Exp) {
      this.cardEvent = Exp
      this.toggleDisplayCard(true)
    },
    setCustomPOIlist() {
      setTimeout(() => {
        let POIlist = [];
        for (var i = 0; i < this.customPOIlistflat.length; i++) {
          let ev = this.customPOIlistflat[i];
          /*ev.direction = {};
          ev.direction.from = { 'lat': ev.lat, 'lon': ev.lon };
          ev.direction.to = { 'lat': this.event.venue.lat, 'lon': this.event.venue.lon};
          ev.direction.distance = "";
          ev.direction.duration = "";
          ev.direction.type = "";*/
          POIlist.push(ev);
        }
        this.customPOIlist = POIlist;
      }, 0);
    },
    closeAllMarker() {
      //this.$refs.filteredExperiences && this.$refs.filteredExperiences.forEach((el) => el.iwMarker.closeMarkerDetails());
      //this.$refs.customPOIlist && this.$refs.customPOIlist.forEach((el) => el.iwMarker.closeMarkerDetails());
      let m = this.mapFix;
      for (let i in m._layers) {
        if (m._layers[i]._path !== undefined) {
          try {
            m._layers[i] && m && m.removeLayer(m._layers[i]);
          } catch (e) {
            console.debug("problem with " + e + m._layers[i]);
          }
        }
      }
    },
    toggleDisplayFilter(value) {
      if (this.isShowFilter === value) return true
      if (value) {
        this.toggleDisplayCard(false)
      }
      this.isShowFilter = value
      if (!this.isMobile) this.toggleDisplayAfisha(!value)
      if (this.isMobile && value && this.activeExp) this.activeExp.setActive(false)
      !value && this.$refs.filter.closeFilter()
    },
    toggleDisplayAfisha(value) {
      if (this.isShowAfisha === value) return
      if (value) {
        this.toggleDisplayCard(false)
        this.toggleDisplayFilter(false)
      }
      this.isShowAfisha = value
      if (this.activeExp) this.activeExp.setActive(false)
    },
    toggleDisplayCard(value) {
      this.isShowCard = value
    },
    toggleDisplayMap(value) {
      if (isMobile && value) {
        this.toggleDisplayAfisha(false)
        this.toggleDisplayFilter(false)
        this.toggleDisplayCard(false)
      }
    },
    getTransferUrl(experience) {
      let baseUrl = 'https://gettransfer.com/transfers/new',
          date = (new Date()),
          dateString = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0'),
          timeString = String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0'),
          urlData = {
            // 'transfer[from][name]': this.experience.title,
            'transfer[from][point]': '(' + this.event.venue.lat + ',' + this.event.venue.lon + ')',
            'transfer[to][point]': '(' + experience.lat + ',' + experience.lon + ')',
            // 'transfer[to][name]': this.experience.title.trim(),
            'transfer[trip_to][date]': dateString + ' ' + timeString,
            // 'transfer[name_sign]': 'John'
            'utm_campaign': CONST.labelSettings.en.label.toLowerCase(),
            'utm_source': 'web',
            'utm_medium': 'partner_promo',
          }

      return baseUrl + '?' + new URLSearchParams(urlData).toString()
    },
    isEventInValidCoordinatesForPopup(Exp, MapBounds) {
      let minHorizontalBuff = 0.000025,
          minVerticalBuff = 0.000025,
          horizontalBuff,
          verticalBuff,
          degree = 23 - this.mapFix._zoom

      horizontalBuff = minHorizontalBuff * (Math.pow(2, degree) - 1) / (2 - 1)
      verticalBuff = minVerticalBuff * (Math.pow(2, degree) - 1) / (2 - 1)

      // console.log(MapBounds)
      // console.log('horiz: ' + horizontalBuff);
      // console.log('vert: ' + verticalBuff);
      // console.log('left: ' + MapBounds._southWest.lng);
      // console.log('top: ' + MapBounds._northEast.lat);
      // console.log('right: ' + MapBounds._northEast.lng);
      // console.log('bottom: ' + MapBounds._southWest.lat);
      // console.log('--------------------------------------------------')
      // console.log(MapBounds._northEast.lat - Exp.coordinates.coordinates[1])
      // console.log(Exp.coordinates.coordinates[0] - MapBounds._southWest.lng)
      // console.log(MapBounds._northEast.lng - Exp.coordinates.coordinates[0])
      // console.log('--------------------------------------------------')

      return (MapBounds._northEast.lat - Exp.coordinates.coordinates[1]) > verticalBuff &&
          (Exp.coordinates.coordinates[0] - MapBounds._southWest.lng) > horizontalBuff &&
          (MapBounds._northEast.lng - Exp.coordinates.coordinates[0]) > horizontalBuff
    },
    filterInit() {
      const activeCategories = [] // TODO: удалить все категории по умолчанию
      this.client.categories.events.forEach(item => {
        if (activeCategories.find(categoryId => categoryId === item.id))
          this.filter.eventCategories.push(item.id)
      })


      // this.filter.prices.push(this.filterPrices[2].value)
      // this.filter.prices.push(this.filterPrices[3].value)
    }
  },
  computed: {
    activeExp() {
      return this.filteredExperience.find(Exp => Exp.isActive)
    },
    expLocal: () => Array.prototype.concat(
        expMoscow.filter((item, index) => {
          item.id = '0' + index
          item.type = 'area'

          return true
        }),
        expSPB.filter((item, index) => {
          item.id = '00' + index
          item.type = 'area'

          return true
        }),
        expGermany.filter((item, index) => {
          item.id = '000' + index
          item.type = 'area'

          return true
        }),
    ),
    groupExperienceByType: function () {
      let groups = {}

      this.filteredExperience.forEach(function (item) {
        if (!groups[item.type]) groups[item.type] = []
        groups[item.type].push(item)
      })

      return groups
    },
    appLink() {
      return navigator.userAgent.includes('Android') ? this.settings.labelSettings.googlePlayLink : this.settings.labelSettings.appStoreLink;
    },
  },
  data() {
    return {
      /*latLngs: [[40.758500000000005,-73.99276],[40.758410000000005,-73.99283000000001],[40.758300000000006,-73.99257],[40.75806,-73.99203],[40.757450000000006,-73.99061],[40.7571,-73.98972],[40.756,-73.98715],[40.75576,-73.98656000000001],[40.755700000000004,-73.98638000000001],[40.75567,-73.98639],[40.75515,-73.98656000000001],[40.75497,-73.98665000000001],[40.75486,-73.98665000000001],[40.7546,-73.98673000000001],[40.754400000000004,-73.98683000000001],[40.75401,-73.98693],[40.753820000000005,-73.98701000000001],[40.7537,-73.98705000000001],[40.753620000000005,-73.98688],[40.75345,-73.98646000000001],[40.752970000000005,-73.98531000000001],[40.75227,-73.98368]],
      latLngs: [[]],*/
      map: null,
      mapFix: null,
      mapBounds: null,
      filteredExperience: [],
      cardEvent: undefined,
      numberExp: 0,
      customPOIlist: [],
      expRequestConfig: {},
      renderList: [],
      renderTimeId: undefined,
      mapIsActive: false,
      filter: {
        eventCategories: [],
        venueCategories: [],
        prices: [],
        distance: '',
        type: 'event',
        dates: {
          dateFrom: moment().locale(this.settings.lang),
          dateTo: moment().add(2, 'days').locale(this.settings.lang)
        }
      },
      cleanFilter: {},
      isMobile,
      isShowAfisha: false,
      isShowFilter: false,
      isShowCard: false,
      shareEvent: undefined,
      loadingMap: true,
      filterPrices: [ // Цены для фильтров
        {
          ru: 'Бесплатно',
          en: 'Free',
          value: {max: 0},
        },
        {
          ru: 'До 500 ₽',
          en: 'To 8 $',
          value: {min: 1, max: 500},
        },
        {
          ru: '500 ₽ - 1 000 ₽',
          en: '8 $ - 16 $',
          value: {min: 500, max: 1000},
        },
        {
          ru: '1 000 ₽ - 3 000 ₽',
          en: '16 $ - 48 $',
          value: {min: 1000, max: 3000},
        },
        {
          ru: '3 000 ₽ - 5 000 ₽',
          en: '48 $ - 80 $',
          value: {min: 3000, max: 5000},
        },
        {
          ru: 'От 5000 ₽',
          en: 'From 80 $',
          value: {min: 5000},
        },
      ],
      mapOptions: {
        type: 'roadmap',
        styles:
            [
              // {
              //   "elementType": "geometry",
              //   "stylers": [
              //     {
              //       "color": "#f5f5f5"
              //     }
              //   ]
              // },
              {
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              // {
              //   "elementType": "labels.text.fill",
              //   "stylers": [
              //     {
              //       "color": "#616161"
              //     }
              //   ]
              // },
              // {
              //   "elementType": "labels.text.stroke",
              //   "stylers": [
              //     {
              //       "color": "#f5f5f5"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "administrative.land_parcel",
              //   "elementType": "labels.text.fill",
              //   "stylers": [
              //     {
              //       "color": "#bdbdbd"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "poi",
              //   "elementType": "geometry",
              //   "stylers": [
              //     {
              //       "color": "#eeeeee"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "poi",
              //   "elementType": "labels.text.fill",
              //   "stylers": [
              //     {
              //       "color": "#757575"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "poi.park",
              //   "elementType": "geometry",
              //   "stylers": [
              //     {
              //       "color": "#e5e5e5"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "poi.park",
              //   "elementType": "labels.text.fill",
              //   "stylers": [
              //     {
              //       "color": "#9e9e9e"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "road",
              //   "elementType": "geometry",
              //   "stylers": [
              //     {
              //       "color": "#ffffff"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "road.arterial",
              //   "elementType": "labels.text.fill",
              //   "stylers": [
              //     {
              //       "color": "#757575"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "road.highway",
              //   "elementType": "geometry",
              //   "stylers": [
              //     {
              //       "color": "#dadada"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "road.highway",
              //   "elementType": "labels.text.fill",
              //   "stylers": [
              //     {
              //       "color": "#616161"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "road.local",
              //   "elementType": "labels.text.fill",
              //   "stylers": [
              //     {
              //       "color": "#9e9e9e"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "transit.line",
              //   "elementType": "geometry",
              //   "stylers": [
              //     {
              //       "color": "#e5e5e5"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "transit.station",
              //   "elementType": "geometry",
              //   "stylers": [
              //     {
              //       "color": "#eeeeee"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "water",
              //   "elementType": "geometry",
              //   "stylers": [
              //     {
              //       "color": "#c9c9c9"
              //     }
              //   ]
              // },
              // {
              //   "featureType": "water",
              //   "elementType": "labels.text.fill",
              //   "stylers": [
              //     {
              //       "color": "#9e9e9e"
              //     }
              //   ]
              // }
            ]
      },
    }
  },
  mounted() {
    this.cleanFilter = {...this.filter}
    this.cleanFilter.eventCategories = []
    this.cleanFilter.venueCategories = []
    this.cleanFilter.prices = []
    this.initMap();
  },
  created() {
    this.isShowAfisha = !isMobile

    if (typeof google !== 'object') global.google = {}
    if (typeof google.maps !== 'object') global.google.maps = {}

    if (this.isMobile) this.toggleDisplayAfisha(true)
  }
}
</script>

<style lang="scss">

.hotel-map-wrap {
  display: flex;
  width: 100%;

  .aside {
    display: flex;
    flex-flow: column;
    min-width: 392px;
    width: 392px;
    z-index: 3001;
    position: relative;

    .header {
      z-index: 1001;
    }

    //.body {
    //  position: relative;
    //}

  }

  .hotel-content-wrap {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    position: relative;

    .header {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;
      min-height: 4.167em;
      height: 4.167em;
      box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.25);
      z-index: 3002;
      background-color: #FFFFFF;
      white-space: break-spaces;
      justify-content: space-between;

      .fun-tam {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .label {
          margin-left: 5px;
          color: #D72A48;
        }

        .description {
          color: #898989;
        }
      }
      .app-links {
        display: flex;
        flex-flow: row;
        align-items: center;
        line-height: 0;

        .img-link {

          .img {
            height: 35px;
            margin-right: .5em;
          }
        }
      }
    }

    .leaflet-map-wrap {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  .bottom-aside {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .get-app-link {
    padding: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #F5366F;
  }

  /*.map-categories {
    position: fixed;
    display: flex;
    background: transparent;
    bottom: 30px;
    left: 0;
    z-index: 1000;
    font-size: 16px;
    color: white;

    .map-category {
      padding: 5px 20px;
      margin: 0 10px;
      border-radius: 20px;

      &.tour {
        background: #DAB785 !important;

        &::after {
          background: #DAB785 !important;
        }
      }

      &.audio-guide {
        background: #DAB785 !important;

        &::after {
          background: #DAB785 !important;
        }
      }

      &.museum {
        background: #065289 !important;

        &::after {
          background: #065289 !important;
        }
      }

      &.music {
        background: #70A288 !important;

        &::after {
          background: #70A288 !important;
        }
      }


      &.theater {
        background: #4D235B !important;

        &::after {
          background: #4D235B !important;
        }
      }

      &.food {
        background: #304A3D !important;

        &::after {
          background: #304A3D !important;
        }
      }

      &.family {
        background: #304A3D !important;

        &::after {
          background: #304A3D !important;
        }
      }

      &.bar {
        background: #304A3D !important;

        &::after {
          background: #304A3D !important;
        }
      }

      &.education {
        background: #D5896F !important;

        &::after {
          background: #D5896F !important;
        }
      }

      &.business {
        background: #D5896F !important;

        &::after {
          background: #D5896F !important;
        }
      }

      &.bus {
        background: #930b31 !important;

        &::after {
          background: #930b31 !important;
        }
      }

      !*
     &.bus{
       background: #031D44  !important;
       &::after {
         background: #031D44 !important;
       }
     }
        *!
      &.activity {
        background: #AFC0FE !important;

        &::after {
          background: #AFC0FE !important;
        }
      }

      &.sport {
        background: #AFC0FE !important;

        &::after {
          background: #AFC0FE !important;
        }
      }

      &.shop {
        background: #FF5349 !important;

        &::after {
          background: #FF5349 !important;
        }
      }
    }
  }*/
}

.hotel-map-wrap.mobile {
  flex-flow: column;

  .aside {
    display: initial;
    min-width: initial;
    width: initial;
    position: initial;

    //.body {
    //  position: initial;
    //}
  }

  //.header-aside {
  //  padding: 10px 5px;
  //  font-family: 'Montserrat', sans-serif;
  //  font-style: normal;
  //  font-weight: 600;
  //  font-size: 11px;
  //  line-height: 13px;
  //  letter-spacing: -0.02em;
  //  display: flex;
  //  align-items: center;
  //
  //  .label {
  //    margin-left: 5px;
  //    color: #D72A48;
  //  }
  //
  //  .description {
  //    color: #898989;
  //  }
  //}

  .hotel-content-wrap {
    flex-flow: column;

    .leaflet-map-wrap {
      height: 100%;
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all .3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  max-height: 0;
}

button {
  color: inherit;
}
</style>
