<template>
    <img className="static-map" :src="url" alt="" @load="$emit('loadResult')"/>
</template>

<script>
import Markers from "@/component/element/google/staticMap/classes/Markers";

export default {
  name: "StaticMapComponent",
  props: {
    center: {
      type: String,
      required: true
    },
    zoom: {
      type: Number,
      default: 15
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    scale: Number,
    format: {
      type: String,
      default: 'png',
      validator(format) {
        return ['png8', 'png', 'png32', 'gif', 'jpg', 'jpg-baseline'].includes(format)
      }
    },
    maptype: {
      type: String,
      default: 'roadmap',
      validator: function (type) {
        return ['roadmap', 'satellite', 'terrain', 'hybrid'].includes(type)
      }
    },
    language: String,
    region: String,
    map_id: String,
    markers: {
      type: Array,
      default: () => [],
      validator: function (markers) {
        if (!markers.length) return true;
        return !markers.some(marker => !(marker instanceof Markers) || !marker.validate())
      }
    }
  },
  data() {
    return {
      key: CONST.GOOGLE_MAP_KEY
    }
  },
  computed: {
    url() {
      let params = {},
      extraParams = '',
      url = 'https://maps.googleapis.com/maps/api/staticmap'

      params.center = this.center
      params.zoom = this.zoom
      params.size = this.width + 'x' + this.height
      if (this.scale) params.scale = this.scale
      params.format = this.format
      params.maptype = this.maptype
      if (this.language) params.language = this.language
      if (this.region) params.region = this.region
      if (this.map_id) params.map_id = this.map_id
      this.markers.forEach(marker => extraParams += '&' + marker.toString())
      params.key = this.key

      return url + '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') + extraParams
    }
  },
}
</script>

<style scoped>
.static-map {
  width: 100%;
}
</style>