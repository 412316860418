<template>
  <ExperiencePopupDesktop
      v-if="!isMobile"
      @logOutBound="LogOutbound()"
      @openCard="$emit('openCard', $event)"
      :experienceUrl="experienceUrl"
      :experience="experience"
      :final_price="final_price"
      :supplier_str="supplier_str"
  />
  <ExperiencePopupMobile
      v-else
      @renderSheetContent="$emit('renderSheetContent', $event)"
      @popupClose="$emit('popupClose')"
      @openCard="$emit('openCard', $event)"
      :experience="experience"
      :final_price="final_price"
      :supplier_str="supplier_str"
      :mainevent="mainevent"
      :settings="settings"
  />
</template>


<script>
import ExperiencePopupDesktop from "@/component/mapV2/experience/ExperiencePopupDesktop";
import ExperiencePopupMobile from "@/component/mapV2/experience/ExperiencePopupMobile";
import Experience from "@/class/client/Experience";

export default {
  components: {ExperiencePopupMobile, ExperiencePopupDesktop},
  props: {
    experience: Experience,
    mainevent: Object,
    settings: Object,
    isMobile: Boolean,
  },
  methods: {
    LogOutbound() {
      Analytic.send('Outbound', {url: this.experienceUrl, partner_name: this.mainevent.name});
      window.fbq && window.fbq('track', 'InitiateCheckout');
    },
    // renderSheetContent(data) {
    //   this.$emit('renderSheetContent', data)
    // }
  },
  computed: {
    final_price() {
      if (this.experience.isType('area') && !this.experience.price) return ''
      return this.experience.price ? this.experience.priceStr : this.$t('map:FREE')
    },
    experienceUrl: function () {
      return this.experience.link ? this.experience.link : '';
    },
    supplier_str: function () {
      let result = ''

      if (!this.experience.provider) return result

      if (this.experience.provider === "getyourguide") {
        result = this.$t('map:at') + ' ' + 'Getyourguide.com';
      } else if (this.experience.provider === "tripster") {
        result = this.$t('map:at') + ' ' + 'Tripster.ru';
      } else if (this.experience.provider) {
        result = this.$t('map:at') + ' ' + this.experience.provider;
      }

      return result
    }
  },
}
</script>