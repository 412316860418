<template>
  <v-card class="card" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" @click="$emit('openCard', experience)">
    <div class="photo-wrap">
      <experience-image :experience="experience" emoji-size="4em" />
      <div class="over-image-wrap">
        <div class="rating-wrap" v-if="experience.type !== 'area' && experience.top">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.09473 1.86651L9.15934 3.99572C9.30452 4.29212 9.69165 4.57642 10.0183 4.63086L11.9479 4.95145C13.1819 5.15712 13.4722 6.05236 12.583 6.9355L11.0829 8.43563C10.8288 8.68968 10.6897 9.17964 10.7684 9.53048L11.1978 11.3875C11.5366 12.8574 10.7563 13.426 9.45574 12.6578L7.64711 11.5871C7.32047 11.3935 6.78212 11.3935 6.44943 11.5871L4.6408 12.6578C3.34633 13.426 2.55997 12.8513 2.89871 11.3875L3.32819 9.53048C3.40682 9.17964 3.2677 8.68968 3.01364 8.43563L1.51351 6.9355C0.63037 6.05236 0.91467 5.15712 2.14865 4.95145L4.07825 4.63086C4.39885 4.57642 4.78598 4.29212 4.93115 3.99572L5.99576 1.86651C6.57646 0.711164 7.52009 0.711164 8.09473 1.86651Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="value">{{ experience.topFloat()}}</span>
        </div>
        <v-avatar
            :size="20"
            v-html="experience.category.iconSvgHtml"
        />
      </div>
    </div>
    <div class="card-body">
      <v-card-title>{{ final_title }}</v-card-title>
      <v-card-text class="spec" v-if="experience.isType('area')">{{ $t('map:Interesting place') }}</v-card-text>
      <v-card-text class="desc">{{ final_desc }}</v-card-text>
      <v-card-actions>
        <v-btn v-if="experience.url"
               class="btn-tickets"
               target="_blank"
               elevation="0"
               :style="getTicketsButtonStyle"
               :href="experience.url"
        >
          <div class="button-label">
            <span class="price"><span class="from" v-if="experience.price">{{ $t('map:from') }} </span>{{final_price}}</span>
            <span class="source">{{ supplier_str }}</span>
          </div>

          <svg class="svg" width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.7" d="M1 10L4.70455 6.29545C5.14205 5.85795 5.14205 5.14205 4.70455 4.70455L1 1" stroke="#FC6921" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </v-btn>
        <v-btn v-else
               class="btn-tickets"
               elevation="0"
               :style="getTicketsButtonStyle"
               :href="experience.url || '#'"
        >
          <div class="button-label">
            <span class="price"><span class="from" v-if="experience.price">{{ $t('map:from') }} </span>{{final_price}}</span>
            <span class="source">{{ supplier_str }}</span>
          </div>

          <svg class="svg" width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.7" d="M1 10L4.70455 6.29545C5.14205 5.85795 5.14205 5.14205 4.70455 4.70455L1 1" stroke="#FC6921" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import Experience from "@/class/client/Experience";
import ExperienceImage from "@/component/mapV2/experience/ExperienceImage.vue";

export default {
  name: 'ExperiencePoster',
  components: {ExperienceImage},
  props: {
    experience: Experience,
    mainevent: Object,
    isMobile: Boolean,
    getTransferUrl: Function,
  },
  methods: {
    onMouseEnter() {
      !this.isMobile && this.experience.setActive(true)
    },
    onMouseLeave() {
      !this.isMobile && this.experience.setActive(false)
    },
  },
  computed: {
    final_price() {
      if (!this.experience.url) return this.$t('map:More')

      if (this.experience.price === 0 || this.experience.price === "0") {
        if (this.experience.type === 'area') return this.$t('map:Look')
        return this.$t('map:FREE');
      }
      return this.experience.priceStr
    },
    final_title() {
      let sliced = this.experience.title.slice(0, 40)

      return sliced.length < this.experience.title.length ? sliced + '...' : sliced;
    },
    final_desc() {
      let sliced

      if (this.experience.isType('area') && !this.experience.desc) return this.experience.address
      if (!this.experience.desc) return ''
      sliced = this.experience.desc.slice(0, 60)

      return sliced.length < this.experience.desc.length ? sliced + '...' : sliced;
    },
    experienceUrl: function () {
      return this.experience.url ? this.experience.url : '';
    },
    supplier_str: function () {
      if (this.experience.supplier && this.experience.supplier === "getyourguide") {
        return 'Getyourguide.com';
      } else if (this.experience.supplier && this.experience.supplier === "tripster") {
        return 'Tripster.ru';
      } else if (this.experience.supplier) {
        return this.experience.supplier;
      } else {
        return "";
      }

    },
    getTicketsButtonStyle() {
      let styleDefault,
          styleBrand

      if (this.experience.price === 0 || this.experience.price === "0")
        return {
          '--borderColor': 'var(--color-partner-identity)',
          '--backgroundColor': 'var(--color-partner-identity)',
          '--color': '#ffffff',
          '--stroke': '#ffffff',
          '--borderColor-hover': 'var(--color-partner-identity)',
          '--backgroundColor-hover': '#ffffff',
          '--color-hover': 'var(--color-partner-identity)',
          '--stroke-hover': 'var(--color-partner-identity)',
        }

      styleDefault = {
        '--borderColor': '#F5366F',
        '--backgroundColor': '#F5366F',
        '--color': '#ffffff',
        '--stroke': '#ffffff',
        '--borderColor-hover': '#F5366F',
        '--backgroundColor-hover': '#ffffff',
        '--color-hover': '#F5366F',
        '--stroke-hover': '#F5366F',
      }

      styleBrand = this.$store.getters.objectValueMapping({
        '--borderColor': 'bgColor',
        '--backgroundColor': 'bgColor',
        '--color': 'fontColor',
        '--stroke': 'fontColor',
        '--borderColor-hover': 'bgColor',
        '--backgroundColor-hover': 'fontColor',
        '--color-hover': 'bgColor',
        '--stroke-hover': 'bgColor',
      })

      return Object.assign(styleDefault, styleBrand)
    },
  }
}
</script>

<style lang="scss" scoped>

.card {
  display: flex;
  margin: 5px 0;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 3px;
  box-shadow: initial !important;
  overflow: hidden;
  height: 130px;
  width: 100%;

  .photo-wrap {
    min-width: 130px;
    min-height: 130px;
    max-width: 130px;
    max-height: 130px;
    position: relative;

    .v-image {
      width: 100%;
      height: 100%;
    }

    .over-image-wrap {
      position: absolute;
      top: 0;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: flex-end;
      justify-content: space-between;
      padding: 5px;
      background: linear-gradient(0deg,rgba(0,0,0,.7) 20%,rgba(0,0,0,.4) 40%,transparent 60%);

      .rating-wrap {
        color: #ffffff;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;

        .value {
          margin-left: 5px;
        }
      }

      .v-avatar /deep/ svg {
        fill: #FFFFFF;
      }
    }
  }

  .card-body {
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;
    overflow: hidden;
    justify-content: space-between;

    .v-card__title {
      display: block;
      width: 100%;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #021430;
      padding: 5px 10px;
      align-items: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .v-card__text {
      padding: 0 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      color: #898989;
      line-height: 1;

      &.desc {
        height: 2.2em;
        overflow: hidden;
        padding-top: .1em;
        padding-bottom: .1em;
      }
    }

    .v-card__actions {
      padding: 5px 10px;
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      align-items: baseline;
      display: flex;

      .v-btn {
        background: #FFFFFF;
        border: 1px solid;
        border-radius: 60px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-transform: initial;
        min-width: 105px;
        height: 34px;
        flex: 0 0 100%;
        max-width: 130px;

        &.btn-tickets {
          border-color: var(--borderColor);
          background-color: var(--backgroundColor);
          color: var(--color);
          font-weight: 700;

          &:hover {
            border-color: var(--borderColor-hover);
            background-color: var(--backgroundColor-hover);
            color: var(--color-hover);

            .svg path{
              stroke: var(--stroke-hover);
            }
          }

          .button-label {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            line-height: 1;

            .source {
              font-size: .8em;
              font-weight: 600;

              &:first-letter {
                text-transform: uppercase;
              }
            }
          }

          .svg {
            position: absolute;
            right: 0.5em;

            path {
              stroke: var(--stroke);
            }
          }
        }

      }

      //.v-btn:not(:first-child) {
      //  margin-left: 10px;
      //}
    }
  }
}
</style>