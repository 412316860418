<template>
  <div class="top-menu">
    <div class="item dates disable-text-select">
      <div class="content" @click="openPopup('dates')">{{ formattedDate }}</div>
      <popup
          class="popup-dates"
          v-click-outside="closePopup"
          :visible="popupShow === 'dates'"
          @apply="applyChange('dates')"
          @close="closePopup"
      >
        <vc-calendar
            class="vc-calendar"
            ref="calendar"
            :first-day-of-week="2"
            :min-date="new Date()"
            :attributes='calendarAttr'
            range="mode"
            is-inline
            @dayclick="selectDate"
        />
      </popup>
    </div>
    <div class="item address" @click="$refs.addressInput.focus()">
      <loupe-icon class="prefix" />
      <input type="text" :value="address" ref="addressInput" @focus="$event.target.select()" />
    </div>
  </div>
</template>

<script>
import popup from "@/component/element/popup";
import LoupeIcon from "@/component/icons/LoupeIcon.vue";

export default {
  name: "TopMenuComponent",
  props: {
    filter: Object,
    event: Object,
  },
  components: {
    LoupeIcon,
    popup,
  },
  data() {
    return {
      form: {
        date: {
          start: undefined,
          end: undefined
        }
      },
      popupShow: false
    }
  },
  computed: {
    formattedDate() {
      let start = this.form.date.start,
          end = this.form.date.end

      if (!start && !end)
        return this.$t('map:Select dates')

      if (!end || start === end) {
        return `${moment(start).format('DD MMMM')}`
      }

      return `${moment(start).format('DD')} - ${moment(end).format('DD MMMM')}`
    },
    calendarAttr() {
      return [
        {
          highlight: true,
          dates: [
            { start: this.form.date.start, end: this.form.date.end },
          ],
        },
        {
          dot: 'red',
          highlight: false,
          popover: {
            label: 'Event starts',
          },
          dates: [
            { start: this.event.dateStart.toDate(), end: this.event.dateEnd.toDate() },
          ],
        },
      ];
    },
    address() {
      let result = []

      if (this.event.venue.address) result.push(this.event.venue.address)

      result = result.join(', ')

      return result
    }
  },
  methods: {
    openPopup(name) {
      if (this.popupShow === name) {
        this.popupShow = false;
        return;
      }
      this.popupShow = name;

      this.$nextTick(() => this.$refs.calendar.showPageRange(this.form.date.start))
    },
    closePopup() {
      this.popupShow = false
    },
    selectDate(event) {
      const date = event.date

      if (this.form.date.start && this.form.date.end) this.resetFormDate()

      if (!this.form.date.start) this.form.date.start = date
      else this.form.date.end = date

      if (this.form.date.start && this.form.date.end)
        if (this.form.date.start > this.form.date.end) {
          const dateStart = this.form.date.start
          this.form.date.start = this.form.date.end
          this.form.date.end = dateStart
        }
    },
    applyChange(name) {
      let result = {}

      if (name === 'dates') {
        if (!result.dates) result.dates = {}
        result.dates.dateFrom = moment(this.form.date.start)
        if (!this.form.date.end) this.form.date.end = this.form.date.start
        if (this.form.date.end) result.dates.dateTo = moment(this.form.date.end)
        this.closePopup()
      }

      this.$emit('applyFilter', result)
    },
    resetFormDate() {
      this.form.date.start = undefined
      this.form.date.end = undefined
    },
    attachAutocomplete() {
      if (!google.maps.places) {
        setTimeout(this.attachAutocomplete, 1000)
        return
      }

      const autocomplete = new google.maps.places.Autocomplete(this.$refs.addressInput)
      autocomplete.addListener("place_changed", () => {
        const place = {
          address_components: [],
          ...autocomplete.getPlace()
        };
        const requestData = {
          reload: true,
          venue: {
            address: place.name,
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng(),
          }
        }

        this.$store.dispatch('eventWidget_load', {
          loadType: 'eventByData',
          request: {
            ...this.$store.state.eventWidget.request,
            ...requestData
          },
        }).then(() => this.$emit('changeLocation'));
      })
    }
  },
  created() {
    this.form.date.start = this.filter.dates.dateFrom.toDate()
    this.form.date.end = this.filter.dates.dateTo.toDate()
  },
  mounted() {
    this.attachAutocomplete()
  }
}
</script>

<style scoped lang="scss">
  .top-menu {
    position: absolute;
    height: 60px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    z-index: 801;

    .item {
      height: 40px;
      margin-right: 10px;
      padding: 0 10px;
      background: #FFFFFF;
      border: 1px solid #DADCE0;
      border-radius: 20px;
      display: flex;
      align-items: center;
      font-size: 15px;
      color: var(--color-901);
      font-weight: bold;
      cursor: pointer;
      position: relative;
      white-space: nowrap;

      &.dates .content {
        text-transform: capitalize;
      }

      &.address {
        width: 100%;
        cursor: text;

        input {
          border: none;
        }
      }

      .prefix {
        margin-right: 10px;
      }

      .iw-popup {
        position: absolute;
        top: 45px;
        left: 0;

        .vc-calendar {
          border: none;
          margin-top: 10px;
        }

        & /deep/ .iw-popup__button {
          color: #FFFFFF !important;
          line-height: .2;
        }

        & /deep/ .iw-popup__button-close {
          top: 5px;
          right: 5px;
        }
      }

      input {
        padding: 0;
        margin: 0;
        color: inherit;
      }
    }
  }
</style>