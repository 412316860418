import { render, staticRenderFns } from "./TopMenuComponent.vue?vue&type=template&id=946a1cf2&scoped=true&"
import script from "./TopMenuComponent.vue?vue&type=script&lang=js&"
export * from "./TopMenuComponent.vue?vue&type=script&lang=js&"
import style0 from "./TopMenuComponent.vue?vue&type=style&index=0&id=946a1cf2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "946a1cf2",
  null
  
)

export default component.exports